import 'react-dropdown/style.css';
import './about.scss';

import background from '../../assets/forest-me.jpg'; // Tell webpack this JS file uses this image
import me2 from '../../assets/me2.jpg'; // Tell webpack this JS file uses this image
import me3 from '../../assets/me3.jpg'; // Tell webpack this JS file uses this image

const styles = {
  header: {
    backgroundImage: `url(${background})`,
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  content: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, .7)",
  },
};

export default function About() {
  return (
    <div style={styles.header}>
      <div style={styles.content}>
        <div className="about-container">
          <div className="about-bio">
            <h1 className="about-header">BIOGRAPHY</h1>
            <div className="about-margin">
              <p>
                I create music inspired by soul, blues, funk, rock, and more! 
              </p>
              <p>
                I am creative and diligent in the studio and will find the right keyboard that fits perfect for your song.
              </p>
              <p>
                I am influenced by the keyboard playing of Stevie Wonder, Elton John, Chick Corea, Prince, Steely Dan, Allman Brothers Band, 
                Ray Charles, Supertramp, Cory Henry, Vince Guaraldi, Bill Withers, Norah Jones, and more
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
